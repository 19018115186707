*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%; // IMPORTANT: This is what Angular Material adds when initialised. Do not remove
  background: var(--next-off-white-50);
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;

  margin: 0;
  padding: 0;
}

img,
picture,
video,
canvas {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

// These properties are the same as Tailwind CSS's preflight reset.
// Tailwind was removed from the project, but without the preflight values, a lot of the styles are broken.
*,
::after,
::before,
::backdrop,
::file-selector-button {
  margin: 0;
  padding: 0;
}

*,
::after,
::before,
::backdrop,
::file-selector-button {
  box-sizing: border-box;
  border: 0 solid;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

ol,
ul,
menu {
  list-style: none;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

img,
video {
  max-width: 100%;
  height: auto;
}
