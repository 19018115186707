// 2023 Next One color Palette

//Brand colors

$next-orange: (
  50: var(--next-orange-50),
  100: var(--next-orange-100),
  200: var(--next-orange-200),
  300: var(--next-orange-300),
  400: var(--next-orange-400),
  500: var(--next-orange-500),
  600: var(--next-orange-600),
  700: var(--next-orange-700),
  800: var(--next-orange-800),
  900: var(--next-orange-900),
);

$orange-color: red;

:root {
  //Brand Colors

  --next-orange-50: #ffefe6;
  --next-orange-100: #ffd6c0;
  --next-orange-200: #ffbb96;
  --next-orange-300: #ffa06b;
  --next-orange-400: #ff8b4c;
  --next-orange-500: #ff772e;
  --next-orange-600: #ff6f27;
  --next-orange-700: #ff6421;
  --next-orange-800: #ff5a1b;
  --next-orange-900: #ff4710;

  --next-green-50: #e1ebe8;
  --next-green-100: #b5ccc7;
  --next-green-200: #83aba1;
  --next-green-300: #51897b;
  --next-green-400: #2c6f5f;
  --next-green-500: #075643;
  --next-green-600: #064f3d;
  --next-green-700: #054534;
  --next-green-800: #043c2c;
  --next-green-900: #022b1e;

  --next-off-white-50: #fefdfd;
  --next-off-white-100: #fbfaf9;
  --next-off-white-200: #f9f7f6;
  --next-off-white-300: #f7f3f2;
  --next-off-white-400: #f5f1ef;
  --next-off-white-500: #f3eeec;
  --next-off-white-600: #f1ecea;
  --next-off-white-700: #efe9e7;
  --next-off-white-800: #ede7e4;
  --next-off-white-900: #eae2df;

  --next-navy-50: #e1e6e8;
  --next-navy-100: #b5c0c5;
  --next-navy-200: #84969f;
  --next-navy-300: #536b78;
  --next-navy-400: #2e4c5b;
  --next-navy-500: #092c3e;
  --next-navy-600: #082738;
  --next-navy-700: #062130;
  --next-navy-800: #051b28;
  --next-navy-900: #02101b;

  //Call To Action (CTA) colors

  --next-cta-green-50: #e4faf2;
  --next-cta-green-100: #bbf3de;
  --next-cta-green-200: #8eecc8;
  --next-cta-green-300: #61e4b1;
  --next-cta-green-400: #3fdea1;
  --next-cta-green-500: #1dd890;
  --next-cta-green-600: #1ad488;
  --next-cta-green-700: #15ce7d;
  --next-cta-green-800: #11c873;
  --next-cta-green-900: #0abf61;

  --next-cta-blue-50: #e8ebfa;
  --next-cta-blue-100: #c6cef3;
  --next-cta-blue-200: #a0adec;
  --next-cta-blue-300: #7a8ce4;
  --next-cta-blue-400: #5e74de;
  --next-cta-blue-500: #415bd8;
  --next-cta-blue-600: #3b53d4;
  --next-cta-blue-700: #3249ce;
  --next-cta-blue-800: #2a40c8;
  --next-cta-blue-900: #1c2fbf;

  --next-cta-purple-50: #f8f3fd;
  --next-cta-purple-100: #eee1fb;
  --next-cta-purple-200: #e3cef9;
  --next-cta-purple-300: #d7baf6;
  --next-cta-purple-400: #cfabf4;
  --next-cta-purple-500: #c69cf2;
  --next-cta-purple-600: #c094f0;
  --next-cta-purple-700: #b98aee;
  --next-cta-purple-800: #b180ec;
  --next-cta-purple-900: #a46ee8;

  //Feedback colors
  --next-feedback-error-50: #fae4e4;
  --next-feedback-error-100: #f2bbbb;
  --next-feedback-error-200: #e98d8d;
  --next-feedback-error-300: #e05f5f;
  --next-feedback-error-400: #da3d3d;
  --next-feedback-error-500: #d31b1b;
  --next-feedback-error-600: #ce1818;
  --next-feedback-error-700: #c81414;
  --next-feedback-error-800: #c21010;
  --next-feedback-error-900: #b70808;

  --next-feedback-warning-50: #fdffe6;
  --next-feedback-warning-100: #f9ffbf;
  --next-feedback-warning-200: #f5ff95;
  --next-feedback-warning-300: #f1ff6b;
  --next-feedback-warning-400: #eeff4b;
  --next-feedback-warning-500: #ebff2b;
  --next-feedback-warning-600: #e9ff26;
  --next-feedback-warning-700: #e5ff20;
  --next-feedback-warning-800: #e2ff1a;
  --next-feedback-warning-900: #ddff10;

  //Grayscale
  --next-gray-scale-50: #ffffff;
  --next-gray-scale-100: #f5f5f5;
  --next-gray-scale-200: #e4e4e4;
  --next-gray-scale-300: #c2c2c2;
  --next-gray-scale-400: #a4a4a4;
  --next-gray-scale-500: #919191;
  --next-gray-scale-600: #7c7c7c;
  --next-gray-scale-700: #686868;
  --next-gray-scale-800: #4d4d4d;
  --next-gray-scale-900: #3d3d3d;
}
