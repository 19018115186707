:root {
  --font-size-xxxs: 0.5rem;
  --font-size-xxs: 0.625rem;
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size: 1rem;
  --font-size-md: 1.125rem;
  --font-size-lg: 1.25rem;
  --font-size-xl: 1.5rem;
  --font-size-xxl: 1.875rem;
  --font-size-jumbo: 2rem;
}
