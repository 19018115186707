@use "src/checkd-colors" as colors;
@forward "./assets/styles/styles.scss";

///////////////////////////////////////////////////////////////////////////////
// NB: These three imports crashes the build process, so they are added to the main index.html file instead
//
// @import url('~https://fonts.googleapis.com/icon?family=Material+Icons');
// @import url('~https://fonts.googleapis.com/icon?family=Avenir');
// @import url('~https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
//
///////////////////////////////////////////////////////////////////////////////

// Uncomment this line to use a prebuilt theme instead of the custom theme
// @import '@angular/material/prebuilt-themes/indigo-pink.css';

// Custom Theme - Import themes first
@import "checkd-dashboard-theme.scss";

// Import the MDC-specific component styles after theme
@import "mdc-component-styles.scss";

// Only include this once
// @forward "./assets/styles/styles.scss"; // Removing duplicate import

@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import "@angular/cdk/overlay-prebuilt.css";

/*
Button styles are applied like this:
  <button class="checkd-btn save-btn-color" mat-button>Foo</button>
  <button class="checkd-btn cancel-btn-color" mat-button>Bar</button>

  (make sure you use this with the mat-button directive)
 */

//Font family 2023

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

button.checkd-btn {
  border-radius: 100px;
  box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

button.checkd-btn[disabled] {
  color: rgba(0, 0, 0, 0.26);
  background-color: rgba(0, 0, 0, 0.12);
}

a.checkd-btn {
  border-radius: 100px;
  box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

button.cancel-btn-color {
  background-color: colors.$checkd-red;
  color: colors.$checkd-white;
}

.save-btn-color {
  background-color: #19e57f;
  color: #ffffff;
}

//This class is still held due to there are some modules that the directives are not possible to import for some reason. Don't use it if its not necessary, use directives
.confirm-btn-color {
  border: none;
  background-color: var(--next-orange-500);
  color: var(--next-off-white-100);
  border-radius: 5px;
  box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: ease-in-out 0.07s;
  position: relative;

  &:hover {
    background: var(--next-orange-100);
    color: var(--next-navy-500);
  }

  &:focus-visible {
    background: var(--next-orange-50);
  }

  &:disabled {
    color: var(--next-gray-scale-400);
    background-color: var(--next-orange-50);
  }
}

.checkd-dark {
  color: colors.$checkd-dark;
}

.checkd-red {
  color: colors.$checkd-red;
}

.checkd-blue {
  color: colors.$checkd-blue;
}

.checkd-yellow {
  color: colors.$checkd-yellow;
}

.checkd-green {
  color: colors.$checkd-green;
}

.checkd-gray {
  color: colors.$checkd-gray;
}

.checkd-white {
  color: colors.$checkd-white;
}

.checkd-dark {
  color: colors.$checkd-dark;
}

.checkd-green-bg {
  background-color: colors.$checkd-green;
}

.checkd-dark-bg {
  background-color: #292b31 !important;
}

.checkd-blue-bg {
  background-color: colors.$checkd-blue;
}

.checkd-gray-bg {
  color: #9b9b9b !important;
}

.checkd-white-bg {
  color: #ffffff !important;
}

.checkd-bg-transparent {
  background-color: rgba(0, 0, 0, 0) !important;
}

// html {
//   font-family: "Lato", sans-serif;
//   -ms-overflow-style: -ms-autohiding-scrollbar;
//   -webkit-box-sizing: border-box;
//   -moz-box-sizing: border-box;
//   box-sizing: border-box;
// }

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

// html,
// body {
//   width: 100vw;
//   height: 100vh;
// }

.checkd-fill-black {
  fill: black;
}

.checkd-stroke-black {
  stroke: black;
}

.checkd-stroke-red {
  stroke: #fc6042;
}

.checkd-stroke-gray {
  stroke: #292b31;
}

.checkd-stroke-light {
  stroke: #9b9b9b;
}

md2-datepicker {
  margin-left: -0.8em;
}

.md2-calendar-header {
  background: colors.$checkd-blue !important;
}

.md2-calendar-body-selected {
  background: colors.$checkd-blue !important;
}

.md2-calendar-body-today:not(.md2-calendar-body-selected) {
  border-color: colors.$checkd-blue !important;
}

.md2-clock-cell.md2-clock-cell-selected {
  background: colors.$checkd-blue !important;
}

.md2-clock-hand {
  background: colors.$checkd-blue !important;
}

.md2-clock-center {
  background: colors.$checkd-blue !important;
}

.empty-list-placeholder {
  margin-top: 70px;
}

.floating-button {
  position: fixed;
  right: 1em;
  bottom: 1em;
  z-index: 100000;
}

.floating-bottom-left {
  position: fixed;
  left: 1em;
  bottom: 1em;
  z-index: 100000;
}

.floating-bottom-right {
  position: fixed;
  bottom: 1em;
  right: 1em;
  z-index: 100000;
}

.checkd-border {
  border-radius: 5px;
  border: 1px solid #dddddd;
  padding: 5px;
  margin: 5px;
}

.item-div-icon {
  border: none;
}

.item-div-icon img {
  width: 51px;
  height: 71px;
}

.item-number-black {
  display: inline-block;
  color: black;
}

.item-number-white {
  display: inline-block;
  color: white;
}

.item-number-1-digit {
  position: absolute;
  font-size: 15px;
  top: 37%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.item-number-2-digit {
  position: absolute;
  font-size: 15px;
  top: 37%;
  left: 1.6rem;
  transform: translate(-50%, -50%);
}

.item-number-3-digit {
  position: absolute;
  font-size: 12px;
  top: 37%;
  left: 45%;
  transform: translate(-50%, -50%);
}

.item-number-4-digit {
  position: absolute;
  font-size: 11px;
  top: 37%;
  left: 45%;
  transform: translate(-50%, -50%);
}

@mixin md-icon-size($size: 24px) {
  font-size: $size;
  height: $size;
  width: $size;
  line-height: $size;
}

.no-padding-dialog .mat-dialog-container {
  padding: 0;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.mat-tab-body {
  overflow: visible !important;
  overflow-x: visible !important;
  overflow-y: visible !important;
}

.mat-tab-body-active {
  overflow: visible !important;
  overflow-x: visible !important;
  overflow-y: visible !important;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  &.dark-backdrop {
    background: colors.$checkd-red;
    opacity: 0.6 !important;
  }

  &.blue-backdrop {
    background: colors.$checkd-blue;
    opacity: 0.6 !important;
  }
}

.fixed-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100% !important;
  z-index: 2;
  background-color: rgba(74, 74, 74, 0.95);
  height: 40px;
}

.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100% !important;
}

.digital-report-static-image {
  &.p-image > img {
    max-height: 200px;
    object-fit: scale-down;
  }
}

// Microsoft login button used in invitation view and login view
.microsoft-login-button {
  border-style: none;
  background-color: #00000000;

  button {
    cursor: pointer;
  }
}

mat-expansion-panel.sortable-drag .mat-expansion-panel-content {
  display: none;
}

.project-gallery-dataview {
  &.p-dataview-grid .p-dataview-content .p-grid {
    justify-content: space-evenly;
  }
}

// app-form-element{
//   width: 100%;
//   border: solid 1px red;
// }

.ks-modal-gallery-backdrop {
  background: #000 !important;
  opacity: 0.85 !important;
}

.ks-modal-gallery-panel {
  z-index: 90000 !important;
}
