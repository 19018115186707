/**
 * MDC Component Styles
 * 
 * This file applies theming to all MDC components and provides a place
 * for component-specific style overrides.
 */

// Import theming functions and the theme from the main theme file
@use "@angular/material" as mat;
@use "checkd-dashboard-theme" as theme;

// =========================================================================
// APPLY MDC COMPONENT THEMES
// =========================================================================

// Apply the light theme to all MDC components
// Using a wildcard selector for better Safari compatibility
// NOTE: This may generate density duplication warnings during development,
// but these can be safely ignored until migration is complete
[class*="mat-mdc-"] {
  @include mat.all-component-themes(theme.$candy-app-mdc-theme);
}

// Apply dark theme to MDC components when inside the .checkd-dark class
.checkd-dark {
  [class*="mat-mdc-"] {
    @include mat.all-component-themes(theme.$dark-mdc-theme);
  }
}

// =========================================================================
// MDC COMPONENT OVERRIDES
// =========================================================================
// Add component-specific overrides below as needed

// =========================================================================
// FORM FIELD OUTLINE FIX
// =========================================================================
// TODO: This fix addresses a bug in Angular Material 16.2.14 where outlined
// form fields display a vertical blue line on the right side of the text.
// After upgrading to Angular Material 17+ or newer, try removing this fix
// to see if the issue has been resolved in the newer version.
// If the issue persists, keep this fix in place.
// =========================================================================

// Fix for the vertical blue line in outlined form fields
.mat-mdc-form-field {
  // Fix for the vertical blue line on the right side of the text
  .mdc-notched-outline__notch {
    border-right: none !important;
  }

  .mdc-notched-outline__trailing {
    border-left: none !important;
  }

  // Set custom outline colors to match the legacy components
  .mdc-notched-outline {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: rgba(0, 0, 0, 0.38);
      border-width: 1px;
    }
  }

  // Ensure proper focus styling with the primary color
  &.mat-focused .mdc-notched-outline {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-width: 2px;
      border-color: #415bd8; // Hardcoded primary color to avoid theme variable issues
    }
  }

  // Ensure proper error styling
  &.mat-form-field-invalid .mdc-notched-outline {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: #f44336; // Standard Material error color
    }
  }
}

// Example: Button adjustments for existing classes
// .mat-mdc-button {
//   &.checkd-btn {
//     // Custom styling for existing .checkd-btn class
//   }
// }
