// TODO move to checkd-common

// NB: The CheckdColors enum in checkd-colors.ts should always correspond to the values found here.
// Make sure to update the values there whenever you make any changes to these values.
$checkd-red: #fc6042;
$checkd-blue: #415bd8;
$checkd-yellow: #f8cb10;
$checkd-green: #19e57f;
$checkd-gray: #9b9b9b;
$checkd-gray-trans: rgba(95, 95, 95, 0.5);
$checkd-white: #ffffff;
$checkd-dark: #4a4a4a;
$checkd-74: #4a4a4a;
// $checkd-main-bg-color: #eff3f6;
// $checkd-main-bg-color: #fefdfd;
$checkd-orange: #ff8c19;
$checkd-black: #2c2c2c;

// 'dark theme' for insights
$insights-dark-top: #26282c;
$insights-dark-bottom: #212328;
