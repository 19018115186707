@use "../abstracts";

html {
  font-size: 16px; //font-base
  scroll-behavior: smooth;
}

body {
  font-family: abstracts.$manrope;
}

//DESKTOP

h1 {
  font-size: var(--font-size-jumbo);
}

h2 {
}

h3 {
}

h4 {
}

p {
}
