@use "@angular/material" as mat;
// Plus imports for other components in your app.

// =========================================================================
// ANGULAR MATERIAL THEMING SETUP
// =========================================================================
// This file provides theming for both legacy and MDC-based components.
// During migration from legacy to MDC components, you will see density
// duplication warnings. These warnings are expected and can be safely ignored.
// They will disappear once migration to MDC is complete and legacy theming
// is removed.
//
// Migration strategy:
// 1. Start with both legacy and MDC themes enabled (current setup)
// 2. Gradually migrate components from legacy imports to MDC imports
// 3. Once all components are migrated, remove all legacy theme code
// =========================================================================

// =========================================================================
// LEGACY COMPONENTS SETUP - DO NOT MODIFY UNTIL FULLY MIGRATED TO MDC
// =========================================================================
// Include the common styles for Angular Material legacy components
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// MDC CORE SETUP - This is required for the new MDC-based components
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$my-palette: (
  50: #e8ebfa,
  100: #c6cef3,
  200: #a0adec,
  300: #7a8ce4,
  400: #5e74de,
  500: #415bd8,
  600: #3b53d4,
  700: #3249ce,
  800: #2a40c8,
  900: #1c2fbf,
  A100: #ffffff,
  A200: #daecff,
  A400: #a7d3ff,
  A700: #8ec6ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$secondary-color: (
  50: #e3fcf0,
  100: #baf7d9,
  200: #8cf2bf,
  300: #5eeda5,
  400: #3ce992,
  500: #19e57f,
  600: #16e277,
  700: #12de6c,
  800: #0eda62,
  900: #08d34f,
  A100: #fcfffd,
  A200: #c9ffda,
  A400: #96ffb6,
  A700: #7dffa4,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// Define the color palettes for your theme
$candy-app-primary: mat.define-palette($my-palette, 500);
$candy-app-accent: mat.define-palette($secondary-color);
$candy-app-warn: mat.define-palette(mat.$red-palette);

// Define theme configuration
$theme-config: (
  color: (
    primary: $candy-app-primary,
    accent: $candy-app-accent,
    warn: $candy-app-warn,
  ),
  typography: mat.define-typography-config(),
  density: 0,
);

// =========================================================================
// LEGACY THEME SETUP - DO NOT MODIFY UNTIL FULLY MIGRATED TO MDC
// =========================================================================
// Create the legacy theme object and apply it
$candy-app-legacy-theme: mat.define-light-theme($theme-config);
@include mat.all-legacy-component-themes($candy-app-legacy-theme);

// =========================================================================
// MDC THEME SETUP - This enables the new MDC-based components
// =========================================================================
// Create the MDC theme object
$candy-app-mdc-theme: mat.define-light-theme($theme-config);

// Apply MDC component themes directly here
// This approach avoids issues with Safari
// by not using any additional selectors
@include mat.button-theme($candy-app-mdc-theme);
@include mat.form-field-theme($candy-app-mdc-theme);
@include mat.input-theme($candy-app-mdc-theme);
@include mat.select-theme($candy-app-mdc-theme);
@include mat.checkbox-theme($candy-app-mdc-theme);
@include mat.radio-theme($candy-app-mdc-theme);
@include mat.tabs-theme($candy-app-mdc-theme);
@include mat.dialog-theme($candy-app-mdc-theme);
@include mat.menu-theme($candy-app-mdc-theme);
@include mat.progress-spinner-theme($candy-app-mdc-theme);
@include mat.table-theme($candy-app-mdc-theme);
@include mat.paginator-theme($candy-app-mdc-theme);
@include mat.card-theme($candy-app-mdc-theme);
@include mat.list-theme($candy-app-mdc-theme);
@include mat.snack-bar-theme($candy-app-mdc-theme);
@include mat.toolbar-theme($candy-app-mdc-theme);
@include mat.slide-toggle-theme($candy-app-mdc-theme);
@include mat.icon-theme($candy-app-mdc-theme);

// =========================================================================
// DARK THEME SETUP
// =========================================================================
// Define an alternate dark theme
$dark-primary: mat.define-palette(mat.$grey-palette, A200);
$dark-accent: mat.define-palette(mat.$light-green-palette, A200, A100, A400);
$dark-warn: mat.define-palette(mat.$red-palette);

// Dark theme configuration
$dark-theme-config: (
  color: (
    primary: $dark-primary,
    accent: $dark-accent,
    warn: $dark-warn,
  ),
  typography: mat.define-typography-config(),
  density: 0,
);

// Create dark theme objects
$dark-legacy-theme: mat.define-dark-theme($dark-theme-config);
$dark-mdc-theme: mat.define-dark-theme($dark-theme-config);

// Apply dark themes
.checkd-dark {
  // Legacy dark theme
  @include mat.all-legacy-component-themes($dark-legacy-theme);

  // MDC dark theme components
  @include mat.button-theme($dark-mdc-theme);
  @include mat.form-field-theme($dark-mdc-theme);
  @include mat.input-theme($dark-mdc-theme);
  @include mat.select-theme($dark-mdc-theme);
  @include mat.checkbox-theme($dark-mdc-theme);
  @include mat.radio-theme($dark-mdc-theme);
  @include mat.tabs-theme($dark-mdc-theme);
  @include mat.dialog-theme($dark-mdc-theme);
  @include mat.menu-theme($dark-mdc-theme);
  @include mat.progress-spinner-theme($dark-mdc-theme);
  @include mat.table-theme($dark-mdc-theme);
  @include mat.paginator-theme($dark-mdc-theme);
  @include mat.card-theme($dark-mdc-theme);
  @include mat.list-theme($dark-mdc-theme);
  @include mat.snack-bar-theme($dark-mdc-theme);
  @include mat.toolbar-theme($dark-mdc-theme);
  @include mat.slide-toggle-theme($dark-mdc-theme);
  @include mat.icon-theme($dark-mdc-theme);
}
