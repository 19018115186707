@use "../0-global/abstracts/font-family" as *;

.button {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: ease-in-out 0.07s;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  overflow: hidden;
  font-size: var(--font-size);
  font-weight: 800;
  font-family: $manrope;
  line-height: 1.3rem;
  min-height: 44px;
  &__primary {
    &--filled {
      background-color: var(--next-orange-500);
      color: var(--next-off-white-100);
      border: solid 1px transparent;
      &:hover {
        background: var(--next-orange-700);
        color: var(--next-off-white-100);
      }

      &:active {
        color: var(--next-off-white-100);
        z-index: 10;

        &::before {
          width: 120%;
          padding-top: 120%;
          transition: width 0.2s ease-out, padding-top 0.2s ease-out;
          z-index: -10;
        }
      }

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 0;
        padding-top: 0;
        border-radius: 100%;
        background-color: var(--next-orange-300);
        transform: translate(-50%, -50%);
      }
      &:active::before {
        width: 120%;
        padding-top: 120%;
        transition: width 0.2s ease-out, padding-top 0.2s ease-out;
      }
      &:focus-visible {
        outline: auto 1px var(--next-orange-50);
        outline-offset: -4px;
        color: var(--next-off-white-100);
      }
      &:disabled {
        color: var(--next-off-white-100);
        background-color: var(--next-orange-500);
        pointer-events: none;
        opacity: 0.4;
      }
    }
    &--outline {
      border: solid 1px var(--next-orange-500);
      background: none;
      color: var(--next-orange-500);

      &:hover {
        background: var(--next-orange-50);
        color: var(--next-orange-500);
      }
      &:active {
        color: var(--next-off-white-100);
        z-index: 10;
        &::before {
          width: 120%;
          padding-top: 120%;
          transition: width 0.2s ease-out, padding-top 0.2s ease-out;
          z-index: -10;
        }
      }
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 0;
        padding-top: 0;
        border-radius: 100%;
        background-color: var(--next-orange-500);
        transform: translate(-50%, -50%);
      }
      &:focus-visible {
        outline: auto 1px var(--next-orange-50);
        outline-offset: -4px;
        color: var(--next-off-white-100);
        background: var(--next-orange-500);
      }
      &:disabled {
        color: var(--next-orange-500);

        pointer-events: none;
        opacity: 0.4;
      }
    }
    &--text-btn {
      border: none;
      background: none;
      color: var(--next-orange-500);
      border: solid 1px transparent;
      box-shadow: none;
      &:hover {
        background: var(--next-orange-50);
        color: var(--next-orange-500);
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      }
      &:active {
        border: solid 1px var(--next-orange-500);
        color: var(--next-off-white-100);
        z-index: 10;
        &::before {
          width: 120%;
          padding-top: 120%;
          transition: width 0.2s ease-out, padding-top 0.2s ease-out;
          z-index: -10;
        }
      }
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 0;
        padding-top: 0;
        border-radius: 100%;
        background-color: var(--next-orange-500);
        transform: translate(-50%, -50%);
      }
      &:focus-visible {
        outline: auto 2px var(--next-orange-500);
        outline-offset: 0px;
        color: var(--next-orange-500);
        background: var(--next-orange-50);
      }
      &:disabled {
        color: var(--next-orange-500);
        pointer-events: none;
        opacity: 0.4;
      }
    }
  }
  &__secondary {
    &--filled {
      background-color: var(--next-green-500);
      color: var(--next-off-white-100);
      border: solid 1px transparent;
      &:hover {
        background: var(--next-green-700);
        color: var(--next-off-white-100);
      }

      &:active {
        color: var(--next-off-white-100);
        z-index: 10;

        &::before {
          width: 120%;
          padding-top: 120%;
          transition: width 0.2s ease-out, padding-top 0.2s ease-out;
          z-index: -10;
        }
      }

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 0;
        padding-top: 0;
        border-radius: 100%;
        background-color: var(--next-green-300);
        transform: translate(-50%, -50%);
      }
      &:active::before {
        width: 120%;
        padding-top: 120%;
        transition: width 0.2s ease-out, padding-top 0.2s ease-out;
      }
      &:focus-visible {
        outline: auto 1px var(--next-white-50);
        outline-offset: -4px;
        color: var(--next-off-white-100);
      }
      &:disabled {
        color: var(--next-off-white-100);
        background-color: var(--next-green-500);
        pointer-events: none;
        opacity: 0.4;
      }
    }
    &--outline {
      border: solid 1px var(--next-green-500);
      background: none;
      color: var(--next-green-500);

      &:hover {
        background: var(--next-green-50);
        color: var(--next-green-500);
      }
      &:active {
        color: var(--next-off-white-100);
        z-index: 10;
        &::before {
          width: 120%;
          padding-top: 120%;
          transition: width 0.2s ease-out, padding-top 0.2s ease-out;
          z-index: -10;
        }
      }
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 0;
        padding-top: 0;
        border-radius: 100%;
        background-color: var(--next-green-500);
        transform: translate(-50%, -50%);
      }
      &:focus-visible {
        outline: auto 1px var(--next-green-50);
        outline-offset: -4px;
        color: var(--next-off-white-100);
        background: var(--next-green-500);
      }
      &:disabled {
        color: var(--next-green-500);

        pointer-events: none;
        opacity: 0.4;
      }
    }
    &--text-btn {
      border: none;
      background: none;
      color: var(--next-green-500);
      border: solid 1px transparent;
      box-shadow: none;
      &:hover {
        background: var(--next-green-50);
        color: var(--next-green-500);
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      }
      &:active {
        color: var(--next-off-white-100);

        z-index: 10;
        &::before {
          width: 120%;
          padding-top: 120%;
          transition: width 0.2s ease-out, padding-top 0.2s ease-out;
          z-index: -10;
        }
      }
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 0;
        padding-top: 0;
        border-radius: 100%;
        background-color: var(--next-green-500);
        transform: translate(-50%, -50%);
      }
      &:focus-visible {
        outline: auto 2px var(--next-green-500);
        outline-offset: 0px;
        color: var(--next-green-500);
        background: var(--next-green-50);
      }
      &:disabled {
        color: var(--next-green-500);
        pointer-events: none;
        opacity: 0.4;
      }
    }
  }
  &__danger {
    background: var(--next-feedback-error-500);
    color: var(--next-off-white-100);
    border: none;
    &:hover {
      background: var(--next-feedback-error-700);
      color: var(--next-off-white-100);
    }
    &:active {
      color: var(--next-off-white-100);
      z-index: 10;
      &::before {
        width: 120%;
        padding-top: 120%;
        transition: width 0.2s ease-out, padding-top 0.2s ease-out;
        z-index: -10;
      }
    }
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 0;
      padding-top: 0;
      border-radius: 100%;
      background-color: var(--next-feedback-error-300);
      transform: translate(-50%, -50%);
    }
    &:focus-visible {
      outline: auto 1px var(--next-orange-50);
      outline-offset: -4px;
      color: var(--next-off-white-100);
    }
    &:disabled {
      color: var(--next-off-white-100);
      background-color: var(--next-feedback-error-500);
      pointer-events: none;
      opacity: 0.4;
    }
  }
  &__rounded {
    border-radius: 100px;
  }
}
